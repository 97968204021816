import React, { useContext, useMemo } from 'react';
import { Box, Container } from '@mui/material';
import { Types, useRouter, useUserContext, QueryParamsContext } from 'common';
import useStyles from './MediathekHome.styles';
import { LecturesSection } from './LecturesSection';
import { DateFilterOptionEnum } from '../../utils/filters/dateFilter';
import {
	Webups,
	BrandContentsList,
	ContinueWatchingList,
	ParterForumContentsList,
	RecommendedContentsList
} from './MediathekHome.components';
import {
	CONTENT_TYPE_QUERY_PARAM_NAME,
	ContentContentTypeFilter
} from '../../components/Filters/Filters.types';
import { CoursesSection } from './CoursesSection/CoursesSection';
import { MediathekHeader } from './MediathekHeader/MediathekHeader';
import { Newsletter } from './Newsletter/Newsletter';
import { MembershipBenefitsBannerBlue } from './MembershipBenefits/MembershipBenefitsBanner';
import { useCurrentUserSpecialityQuery } from '../../graphql/catalog/queries';
import { TopicsSelector } from '../../components/TopicsHeader/TopicsSelector/TopicSelector';
import { useIntl } from 'react-intl';

export const getContentTypeDateQueryParams = (
	contentType: ContentContentTypeFilter,
	date?: DateFilterOptionEnum
) => {
	return `${CONTENT_TYPE_QUERY_PARAM_NAME}=${contentType}${date ? `&date=${date}` : ''}`;
};

interface Props {
	initialTopicCode: string;
}
export const MediathekHomePageA = ({ initialTopicCode }: Props) => {
	const { formatMessage } = useIntl();
	const classes = useStyles();
	const { query: queryState } = useContext(QueryParamsContext);
	const { isLoggedIn } = useUserContext();

	const { setQuery, query } = useRouter<{ topic: string | undefined }>();
	const selectedTopicCode = useMemo(
		() => query.topic || (queryState.topic as string | undefined) || initialTopicCode,
		[query.topic, initialTopicCode, queryState.topic]
	);

	useCurrentUserSpecialityQuery({
		onCompleted: (data) => {
			const specialityCode = data.topicDocuments.data[0]?.code;
			if (!selectedTopicCode && specialityCode !== selectedTopicCode) {
				setQuery({
					topic: specialityCode
				});
			}
		},
		skip: !isLoggedIn || !selectedTopicCode
	});

	return (
		<Box className={classes.container}>
			<Container maxWidth="xl" disableGutters>
				<MediathekHeader />
				<Box className={classes.topicsContainer}>
					<TopicsSelector
						displayTopicButtons
						useQueryState={false}
						displayAllTopic={false}
					/>
				</Box>
				<Box marginX="12px">
					<CoursesSection
						title={formatMessage({ id: 'media-library.home.book-old-courses' })}
						selectedTopicCode={selectedTopicCode}
					/>
				</Box>
				<RecommendedContentsList selectedTopicCode={selectedTopicCode} />
				<Webups selectedTopicCode={selectedTopicCode} />
			</Container>
			<MembershipBenefitsBannerBlue />
			<Container maxWidth="xl" disableGutters>
				<ContinueWatchingList />
				<BrandContentsList
					brand={Types.ProductBrand.Summedup}
					topicCode={selectedTopicCode}
				/>
				<BrandContentsList brand={Types.ProductBrand.Skill} topicCode={selectedTopicCode} />
				<ParterForumContentsList selectedTopicCode={selectedTopicCode} />
				<LecturesSection selectedTopicCode={selectedTopicCode} />
				<Newsletter />
			</Container>
		</Box>
	);
};
