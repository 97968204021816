import { Box, Container, useTheme } from '@mui/material';
import {
	booleanFilter,
	QueryParamsContext,
	Types,
	useRouter,
	useUserContext,
	useIsDesktop,
	getCountryCode
} from 'common';
import { flatMap, groupBy, mapValues, omit } from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
	SelectorTopic,
	TopicsSelector
} from '../../components/TopicsHeader/TopicsSelector/TopicSelector';
import {
	useCurrentUserSpecialityQuery,
	useMediathekCoursesQuery,
	useTopicSortOrderQuery
} from '../../graphql/catalog/queries';
import { CoursesTopicTable } from './CoursesSection/components/CoursesTopicTable';
import { CoursesSection } from './CoursesSection/CoursesSection';
import { getFetchCoursesVariables } from './CoursesSection/CoursesSection.utils';
import { LecturesSection } from './LecturesSection';
import { MediathekHeader } from './MediathekHeader/MediathekHeader';
import {
	BrandContentsList,
	ContinueWatchingList,
	ParterForumContentsList,
	RecommendedContentsList,
	Webups
} from './MediathekHome.components';
import useStyles from './MediathekHome.styles';
import { MembershipBenefitsBannerBlue } from './MembershipBenefits/MembershipBenefitsBanner';
import { Newsletter } from './Newsletter/Newsletter';

interface Props {
	initialTopicCode: string;
}
export const MediathekHomePageB = ({ initialTopicCode }: Props) => {
	const classes = useStyles();
	const { isLoggedIn } = useUserContext();
	const { setQuery, query } = useRouter<{ topic: string | undefined }>();
	const { query: queryState } = useContext(QueryParamsContext);
	const intl = useIntl();
	const { isMobile } = useTheme();
	const isDesktop = useIsDesktop({ defaultMatches: !isMobile });
	const countryCode = getCountryCode(intl.locale);

	const selectedTopicCode = useMemo(
		() => query.topic || (queryState.topic as string | undefined) || initialTopicCode,
		[query.topic, initialTopicCode, queryState.topic]
	);

	const { data: orderedTopicsIds } = useTopicSortOrderQuery({
		skip: !isLoggedIn
	});

	useCurrentUserSpecialityQuery({
		onCompleted: (data) => {
			const specialityCode = data.topicDocuments.data[0]?.code;
			if (!selectedTopicCode && specialityCode !== selectedTopicCode) {
				setQuery({
					topic: specialityCode
				});
			}
		},
		skip: !isLoggedIn || !selectedTopicCode
	});

	const { data: coursesData } = useMediathekCoursesQuery({
		variables: getFetchCoursesVariables(countryCode)
	});

	// Topic codes sorted by the amount of courses each topic have
	const sortedByCoursesTopicCodes = useMemo(() => {
		const flattened = flatMap(coursesData?.contentDocuments.data, (i) =>
			i.product?.topics.map((topic) => ({ ...i, topic }))
		);
		const grouped = groupBy(flattened, 'topic.code');
		const res = mapValues(grouped, (group) => group.map((item) => omit(item, 'topic')));
		return Object.entries(res).sort((a, b) => {
			return a[1].length > b[1].length ? -1 : 1;
		});
	}, [coursesData?.contentDocuments.data]);

	// displays topic button on each topic that have courses
	const topicsMapper = useCallback(
		(topics: Array<SelectorTopic>) => {
			const ordered = isLoggedIn
				? orderedTopicsIds?.topicDocuments.data
						.map(({ id }) => topics.find((t) => t.id === id))
						.filter(booleanFilter)
				: topics;

			const withCourses = sortedByCoursesTopicCodes
				.map((code) => topics.find((t) => t.code === code[0]))
				.filter(booleanFilter);

			const orderedWithDisplayButton =
				ordered?.map((o) => {
					if (withCourses.find((withCourse) => withCourse.id === o.id)) {
						return {
							...o,
							displayButton: true
						};
					}
					return o;
				}) ?? [];

			if (!isDesktop) {
				return orderedWithDisplayButton.map((t, i) => {
					if (i <= 3) {
						return t;
					}
					return {
						...t,
						displayButton: false
					};
				});
			}
			return orderedWithDisplayButton;
		},
		[sortedByCoursesTopicCodes, isLoggedIn, isDesktop]
	);

	return (
		<Box className={classes.container}>
			<Container maxWidth="xl" disableGutters>
				<MediathekHeader />
				<Box className={classes.topicsContainer}>
					<TopicsSelector
						displayTopicButtons
						displayAllTopic={false}
						popoverPosition="end"
						useQueryState={false}
						displayAbbreviation
						mapTopics={topicsMapper}
					/>
				</Box>
				<Box marginX="12px">
					<CoursesTopicTable
						selectedTopicCode={selectedTopicCode}
						sortedByCoursesTopicCodes={sortedByCoursesTopicCodes}
					/>
					<CoursesSection
						filterSelectedTopicCode
						selectedTopicCode={selectedTopicCode}
						title={intl.formatMessage({ id: 'media-library.home.book-courses' })}
					/>
				</Box>
				<RecommendedContentsList selectedTopicCode={selectedTopicCode} />
				<Webups selectedTopicCode={selectedTopicCode} />
				<MembershipBenefitsBannerBlue />
				<ContinueWatchingList />
				<BrandContentsList
					brand={Types.ProductBrand.Summedup}
					topicCode={selectedTopicCode}
				/>
				<BrandContentsList brand={Types.ProductBrand.Skill} topicCode={selectedTopicCode} />
				<ParterForumContentsList selectedTopicCode={selectedTopicCode} />
				<LecturesSection selectedTopicCode={selectedTopicCode} />
				<Newsletter />
			</Container>
		</Box>
	);
};
