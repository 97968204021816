import React from 'react';
import { Box, LinearProgress } from '@mui/material';
import { TitleTooltip, Typography } from 'components';
import {
	formatters,
	FreeBadge,
	getProgressPercentage,
	buildURLWithParams,
	MEDIATHEK_URLS,
	Types,
	VideoRating,
	Speakers,
	SpeakerVariant,
	CATALOG_URLS,
	NextLink
} from 'common';
import { LectureThumbnail } from '../LectureThumbnail/LectureThumbnail';

import useStyles from './LectureItemWithProgress.styles';
import { LectureItemWithProgressProps } from './LectureItemWithProgress.types';

const getLink = ({
	contentId,
	parentContentId,
	parentContentType,
	brand,
	slug
}: {
	contentId: string;
	parentContentId: string | null | undefined;
	parentContentType: Types.ContentType | null | undefined;
	brand: Types.ProductBrand | null | undefined;
	slug: string | null | undefined;
}) => {
	let parentContentIdToUse = null;
	if (
		parentContentType === Types.ContentType.Course ||
		parentContentType === Types.ContentType.Webinar
	) {
		parentContentIdToUse = parentContentId;
	}
	let newUrl = '';
	// This is an additional content from a partner forum, redirect to the partnerforum page
	if (brand === null && slug) {
		newUrl = buildURLWithParams(CATALOG_URLS.partnerVideo, [slug, 'all', contentId]);
	} else {
		newUrl = buildURLWithParams(MEDIATHEK_URLS.content, [parentContentIdToUse ?? contentId]);
		const videoPlayingUrlPath = `/${contentId}`;
		if (parentContentIdToUse) {
			newUrl += videoPlayingUrlPath;
		}
	}
	return newUrl;
};

const LectureItemWithProgress = (props: LectureItemWithProgressProps) => {
	const {
		thumbnailUrl,
		duration,
		isFree,
		title,
		speakers,
		time,
		contentId,
		parentContentId,
		parentContentType,
		rating,
		brand,
		slug
	} = props;
	const classes = useStyles();
	const progressPercentage = getProgressPercentage(time, duration);

	return (
		<NextLink
			href={getLink({
				contentId,
				parentContentId,
				parentContentType,
				brand,
				slug
			})}
		>
			<Box className={classes.container}>
				<Box>
					<Box className={classes.imageContainer}>
						<div className={classes.playerContainer}>
							<LectureThumbnail thumbnailUrl={thumbnailUrl} />
							{duration && (
								<Typography
									className={classes.duration}
									title={formatters.formatDurationInSeconds(duration)}
								/>
							)}
							{isFree && <FreeBadge classes={{ freeBadge: classes.freeBadge }} />}
						</div>
					</Box>
				</Box>
				<LinearProgress
					variant="determinate"
					color="secondary"
					className={classes.progress}
					value={progressPercentage}
				/>
				<Box className={classes.descriptionContainer}>
					<VideoRating rating={rating} />
					<TitleTooltip title={title}>
						<Typography
							title={title}
							className={classes.title}
							color="titleActive"
							variant="textSmall"
						/>
					</TitleTooltip>
					<Speakers
						speakers={speakers}
						variant={SpeakerVariant.BRIEF}
						classes={{
							speakerText: classes.subtitle
						}}
					/>
				</Box>
			</Box>
		</NextLink>
	);
};

export default LectureItemWithProgress;
